import React, { useEffect, useState } from "react";
import { EmptyMessage, Main } from "../styled/GlobalStyled";
import { useSelector } from "react-redux";
import FallbackLoading from "../components/FallbackLoading";

import AppBar from "../components/utils/PrimaryAppBar";
import { Box, styled } from "@mui/material";

import OrderCard from "../components/orders/OrderCard";
import SearchPage from "../components/utils/SearchPage";
import DatePicker from "../components/utils/DatePicker";
import { useInfiniteQuery } from "react-query";
import { requestData } from "../lib/rest_api_helper";
import ThreeDotsLoading from "../components/utils/ThreeDotsLoading";

const StyledOrderList = styled(Box)`
  padding-inline: 1.25rem;
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 2rem;
`;

export const ErrorText = styled("p")`
  color: ${({ theme }) => theme.palette.red_1};
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const OrderList = () => {
  const [totalPages, setTotalPages] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [error, setError] = useState("");
  const [showDatePicker, setShowDatePicker] = useState({
    show: false,
    date: "Any Time",
  });

  const { role, userID } = useSelector((state) => state.auth);

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetchError,
  } = useInfiniteQuery(
    ["orders", userID, role, showDatePicker.date],
    ({ pageParam = 1 }) => getOrders(pageParam, showDatePicker.date),
    {
      getNextPageParam: (_, allPages) => {
        const nextPage = allPages.length + 1;
        return allPages.length < totalPages ? nextPage : totalPages;
      },
      onError: (err) => {
        switch (err) {
          case "AxiosError: Request failed with status code 401":
            setError("Session Expired. Please login again");
            break;
          case "AxiosError: Network Error":
            setError("Network Error. Check your connection");
            break;
          default:
            setError("Something Went Wrong");
            break;
        }
      },
      refetchInterval: 60000,
    }
  );

  async function getOrders(page, date) {
    if (!(role && userID)) return;
    let query = page;
    if (date !== "Any Time") query += "&date=" + date;
    try {
      const { data } = await requestData("orders?page=" + query);
      if (page === 1) {
        const pages = Math.ceil(data?.result?.meta?.total / 20);
        setTotalPages(pages);
      }
      if (data.result) return data.result;
    } catch (error) {
      throw new Error(error).message;
    }
  }

  useEffect(() => {
    let fetching = false;
    const handleScroll = async (e) => {
      const { scrollHeight, scrollTop, clientHeight } =
        e.target.scrollingElement;
      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.2) {
        fetching = true;
        if (hasNextPage && data.pages.length < totalPages)
          await fetchNextPage();
        fetching = false;
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [fetchNextPage, hasNextPage]);

  const controlDate = (show, date = showDatePicker.date) =>
    setShowDatePicker({ show: show, date: date });

  const toggleDrawer = () => setOpenDrawer(!openDrawer);

  return (
    <Main>
      <AppBar
        selectedDate={showDatePicker?.date}
        toggleDrawer={toggleDrawer}
        withAction={true}
        text="Order List"
        toggleDatePicker={controlDate}
      />
      <SearchPage toggleDrawer={toggleDrawer} openDrawer={openDrawer} />
      <DatePicker modal={showDatePicker} control={controlDate} />
      {isLoading ? (
        <FallbackLoading />
      ) : isError ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <>
          {data?.pages?.length > 0 ? (
            <StyledOrderList
              display="grid"
              gridTemplateColumns="1fr"
              gap="1rem"
            >
              {data?.pages?.map((page, index) => (
                <div key={index}>
                  {page?.data?.length ? (
                    page?.data?.map((order) => (
                      <OrderCard key={order?.id} role={role} data={order} />
                    ))
                  ) : (
                    <EmptyMessage className="empty">
                      You don't have any orders yet
                    </EmptyMessage>
                  )}
                </div>
              ))}
            </StyledOrderList>
          ) : (
            ""
          )}
        </>
      )}
      {(isRefetchError || isFetchingNextPage) && data?.pages?.length > 0 && (
        <ThreeDotsLoading error={isRefetchError} />
      )}
    </Main>
  );
};

export default OrderList;
