import React, { useMemo, useState } from "react";
import Card from "@mui/material/Card";
import { useLocation, useNavigate } from "react-router";

import { Box, Divider, ListItem, styled } from "@mui/material";
import { SelectDeliveryMan } from "../../styled/GlobalStyled";

import BadgeIcon from "@mui/icons-material/Badge";
import TimeIcon from "@mui/icons-material/AccessTimeFilled";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";

import { FilledButton } from "../utils/Buttons";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment/moment";

const OrderID = styled("h5")`
  background-color: ${({ theme }) => theme.palette.gray_1};
  padding-block: 1rem;
  font-size: 1rem;
  padding-inline: 1rem;
  box-shadow: ${({ theme }) => theme.shadows[1]};
`;

const DeliveryContainer = styled("div")`
  padding-inline: 1rem;
  padding-bottom: 1rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  h6 {
    color: ${({ theme }) => theme.palette.gray_9};
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
`;

const StyledListItem = styled(ListItem)`
  align-items: flex-start;
  color: ${({ theme }) => theme.palette.gray_5};
  font-size: 0.8rem;
  gap: 0.5rem;
  padding-block: 1rem;
  ul {
    .data {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.gray_9};
      width: 100%;
    }
  }
`;

const OrderCard = ({ data, role }) => {
  let order = useMemo(() => {
    return { ...data };
  }, [data]);
  if (order?.diff_area_status === 1) {
    order.customer = {
      address: `${data?.shipping_address}, ${data?.shipping_township?.name}`,
      email: data?.shipping_email,
      name: data?.shipping_name,
      phone: data?.shipping_phone,
    };
  } else {
    order.customer = {
      address: `${data?.billing_address}, ${data?.billing_township?.name}`,
      email: data?.billing_email,
      name: data?.billing_name,
      phone: data?.billing_phone,
    };
  }
  Object.keys(order).forEach((key) => {
    if (key.startsWith("shipping") || key.startsWith("billing")) {
      delete order[key];
    }
  });
  const [deliveryMan, setDeliveryMan] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const orderId = params.get("order_id");
  const manId = params.get("man_id");
  const name = params.get("name");
  const phone = params.get("phone");

  const goToContactList = () => {
    navigate(`/contact-list?id=${order?.order_number}&origin=orders`, {
      state: order,
    });
  };

  useEffect(() => {
    if (name && orderId === order?.order_number) {
      setDeliveryMan(name);
    }
  }, [orderId, name, order]);

  const assignDeliveryMan = () => {
    navigate(`/orders/${orderId}?name=${name}&man_id=${manId}&phone=${phone}`, {
      state: order,
    });
  };

  const handleOrderDetail = () => {
    navigate("/orders/" + data?.order_number, { state: order });
  };

  return (
    <Card
      onClick={handleOrderDetail}
      sx={{ height: "max-content", marginTop: "1rem" }}
    >
      <OrderID>Order ID : {order?.order_number}</OrderID>
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <StyledListItem>
          <BadgeIcon sx={{ fontSize: "1.25rem" }} />
          <ul>
            <li>Customer</li>
            <li className="data">{order?.customer?.name}</li>
          </ul>
        </StyledListItem>
        <StyledListItem>
          <TimeIcon sx={{ fontSize: "1.25rem" }} />
          <ul>
            <li>Date</li>
            <li className="data">{moment(order?.created_at).format("ll")}</li>
          </ul>
        </StyledListItem>
        <Box gridColumn="span 2" sx={{ marginTop: "-1rem" }}>
          <StyledListItem>
            <PlaceIcon sx={{ fontSize: "1.25rem" }} />
            <ul>
              <li>Place</li>
              <li className="data place">{order?.customer?.address}</li>
            </ul>
          </StyledListItem>
        </Box>
      </Box>
      {role === "delivery" && pathname === "/orders" && (
        <DeliveryContainer onClick={(e) => e.stopPropagation()}>
          <Divider />
          <h6>Delivery Man</h6>
          <SelectDeliveryMan onClick={goToContactList}>
            {deliveryMan ? deliveryMan : "Select Delivery Man"}{" "}
            <ArrowDownIcon />
          </SelectDeliveryMan>
          {deliveryMan && (
            <FilledButton func={assignDeliveryMan} text="Assign Delivery Man" />
          )}
        </DeliveryContainer>
      )}
    </Card>
  );
};

export default OrderCard;
