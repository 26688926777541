import { Card, ListItem, styled } from "@mui/material";
import { motion } from "framer-motion";

export const StyledCard = styled(Card)`
  min-height: 87px;
  border-left: solid 7px
    ${({ match, theme }) =>
      match === "true" ? theme.palette.primary.main : theme.palette.red_1};
`;

export const StyledParentProduct = styled("div")`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 87px;
`;

export const FreeProducts = styled("div")`
  font-size: 14px;
  padding-bottom: 1rem;
  border-bottom: solid 1px ${({ theme }) => theme.palette.gray_2};
  h4 {
    color: tomato;
    margin-top: 0.5rem;
  }
  .item {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
  }
  figure {
    margin-inline: 0.5rem;
    height: 40px;
    width: 40px;
    img {
      width: 40px;
      border-radius: 4px;
      aspect-ratio: 1 / 1;
    }
  }
`;

export const StyledListItem = styled(ListItem)`
  margin-top: -1px;
  padding-block: 1rem;
  opacity: ${({ clicked }) => (clicked === "true" ? "50%" : "100%")};
  figure {
    margin-inline: 0.5rem;
    height: 60px;
    width: 60px;
    img {
      width: 60px;
      border-radius: 4px;
      aspect-ratio: 1 / 1;
    }
  }
`;

export const ItemText = styled("div")`
  margin-left: 0.25rem;
  font-weight: 600;
  .name {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.gray_9};
    margin-bottom: 0.1rem;
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .qty {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.gray_6};
  }
  .variations {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    justify-content: flex-start;
    margin-top: 3px;
    .variation {
      font-size: 0.6rem;
      padding-inline: 0.2rem;
      padding-block: 0.1rem;
      white-space: nowrap;
    }
    .color {
      width: 1.4rem;
      height: 1rem;
    }
  }
`;

export const ControlQty = styled("div")`
  color: ${({ theme, disabled }) =>
    disabled === true ? theme.palette.gray_5 : theme.palette.gray_9};
  font-weight: 600;
  font-size: 0.8rem;
  width: 60px;
  height: 35px;
  border-radius: 4px;
  background-color: ${({ theme, disabled }) =>
    disabled === true ? theme.palette.gray_2 : "white"};
  border: ${({ disabled }) =>
    disabled === true ? "none" : "solid 1px #d5d5d5"};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ControlBtn = styled(motion.div)`
  position: absolute;
  left: 120%;
  z-index: 10;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 100%;
  width: 5rem;
  border-radius: 4px;
  button {
    width: 100%;
    border: none;
    background-color: white;
    color: ${({ theme }) => theme.palette.primary.main};
    padding-top: 2px;
    height: 100%;
  }
`;
