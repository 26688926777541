import React, { useMemo } from "react";
import { Divider } from "@mui/material";
import AppBar from "../components/utils/AppBarWithBackBtn";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  DividedByAlphabet,
  NameContainer,
  NameNavigator,
  SearchBar,
  StyledTextField,
  ErrorText,
} from "../styled/ContactList";
import { useSelector } from "react-redux";
import { requestData } from "../lib/rest_api_helper";
import { useQuery } from "react-query";
import { useEffect } from "react";

import FallbackLoading from "../components/FallbackLoading";

const alphabets = {
  a: [],
  b: [],
  c: [],
  d: [],
  e: [],
  f: [],
  g: [],
  h: [],
  i: [],
  j: [],
  k: [],
  l: [],
  m: [],
  n: [],
  o: [],
  p: [],
  q: [],
  r: [],
  s: [],
  t: [],
  u: [],
  v: [],
  w: [],
  x: [],
  y: [],
  z: [],
};
const ContactList = () => {
  const { state: order } = useLocation();

  const navigate = useNavigate();
  const { role } = useSelector((state) => state.auth);

  const [filteredList, setFilteredList] = useState({});
  const [searchedName, setSearchedName] = useState("");
  const [rawData, setRawData] = useState([]);

  //Get Delivery Men List from API
  const { isLoading, isError } = useQuery(
    "delivery-men",
    getSortedDeliveryMen,
    {
      cacheTime: 50000,
    }
  );

  const refs = useMemo(
    () => Object.keys(alphabets)?.map(() => React.createRef()),
    []
  );
  const [params] = useSearchParams();

  const origin = params.get("origin");

  async function getSortedDeliveryMen() {
    try {
      const { data } = await requestData("admins?role_id=17");
      if (data.result) {
        const sortedByAlphabets = JSON.parse(JSON.stringify(alphabets));
        const raw = [];
        data?.result?.data?.forEach((info) => {
          raw.push({
            name: info?.name,
            id: info?.id,
            phone: info?.phone,
          });
          sortedByAlphabets[info?.name[0]?.toLowerCase()]?.push({
            name: info?.name,
            id: info?.id,
            phone: info?.phone,
          });
        });
        setRawData(raw);
        setFilteredList(sortedByAlphabets);
        return sortedByAlphabets;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const scrollTo = (index) => {
    if (typeof window !== undefined) {
      const offset =
        refs[index]?.current?.getBoundingClientRect().top +
        window.pageYOffset -
        70;
      window.scrollTo({ top: offset, behavior: "smooth" });
    }
  };

  const filterName = (e) => {
    const value = e.target.value?.toLowerCase();
    setSearchedName(e.target.value);

    let filtered = JSON.parse(JSON.stringify(alphabets));
    rawData?.forEach((data) => {
      if (data?.name?.toLowerCase()?.includes(value)) {
        filtered[data?.name[0]?.toLowerCase()]?.push({
          name: data?.name,
          id: data?.id,
          phone: data?.phone,
        });
      }
    });
    setFilteredList(filtered);
  };

  useEffect(() => {
    if (role !== "delivery") {
      navigate("/orders");
    }
  }, []);
  return (
    <main
      style={{
        marginTop: "5rem",
        paddingInline: "1rem",
        paddingBottom: "2rem",
      }}
    >
      <AppBar text="Back" />
      <SearchBar>
        <SearchIcon />
        <StyledTextField
          type="text"
          placeholder="Search Delivery Man"
          value={searchedName}
          onChange={filterName}
        ></StyledTextField>
      </SearchBar>
      <NameNavigator>
        {Object.keys(filteredList)?.map(
          (alpha, index) =>
            filteredList[alpha]?.length > 0 && (
              <button
                className="alphabet"
                key={index}
                onMouseOver={() => scrollTo(index)}
              >
                {alpha}
              </button>
            )
        )}
      </NameNavigator>
      {isLoading ? (
        <FallbackLoading />
      ) : isError ? (
        <ErrorText>Failed to load data</ErrorText>
      ) : Object.keys(filteredList)?.length > 0 ? (
        Object.keys(filteredList)?.map((alpha, index) => (
          <div key={index}>
            {filteredList[alpha]?.length > 0 && (
              <DividedByAlphabet ref={refs[index]}>
                <h6>{alpha}</h6>
                <Divider sx={{ marginBlock: "0.5rem" }} />
                <NameContainer>
                  {filteredList[alpha]?.map((info, index) => (
                    <p
                      onClick={() =>
                        navigate(
                          `/${origin}?order_id=${order?.order_number}&name=${info?.name}&man_id=${info?.id}&phone=${info?.phone}`,
                          { state: order }
                        )
                      }
                      className="link"
                      key={index}
                    >
                      {info.name}
                    </p>
                  ))}
                </NameContainer>
              </DividedByAlphabet>
            )}
          </div>
        ))
      ) : (
        ""
      )}
    </main>
  );
};

export default ContactList;
