import { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import { Typography, styled } from "@mui/material";
import { FilledButton, OutlinedButton } from "./Buttons.js";

import PersonThinking from "../../assets/icons/person-thinking.png";

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.25rem;
  img {
    width: 100%;
    max-width: 80px;
    margin-bottom: 1.5rem;
  }
  .error {
    font-size: 14px;
    margin-top: 1rem;
    color: ${({ theme }) => theme.palette.red_1};
  }
`;

const TypoBold = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.gray_9};
  text-align: center;
  font-size: 1rem;
  width: 100%;
`;
const TypoP = styled(Typography)`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.gray_4};
  text-align: center;
  margin-bottom: 1rem;
  width: 100%;
  font-family: "Poppins", sans-serif;
`;

const Buttons = styled("div")`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

export default function CrateDialog({
  openModal,
  handleClose,
  role,
  deliMan,
  func,
  isError = false,
}) {
  const text = useMemo(() => {
    return {
      picker: "to confirm products in crate box?",
      packer: "that you packed all products in the order?",
      qc: "that you checked quality of all products in the order?",
      delivery: `that you assigned ${deliMan} as a delivery man?`,
      delivering: "that you deliver products?",
    };
  }, [deliMan]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        open={openModal}
      >
        <StyledDialogContent>
          <img src={PersonThinking} alt="Person Thinking" />
          <TypoBold>Are you sure</TypoBold>
          <TypoBold gutterBottom>{text[role]}</TypoBold>
          {/* <TypoP>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do.Lorem ipsum dolor sit amet, consectetur adipiscing
          </TypoP> */}
          <Buttons>
            <OutlinedButton text="Not Now" func={handleClose} />
            <FilledButton text="Sure" func={func} />
          </Buttons>
          {isError && <p className="error">Something went wrong. Try later.</p>}
        </StyledDialogContent>
      </Dialog>
    </>
  );
}
