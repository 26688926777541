import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

const ProtectedRoute = ({ children }) => {
  const { authorized } = useSelector((state) => state.auth);
  if (authorized !== false) return children;
  else return <Navigate to={"/login"} />;
};

export default ProtectedRoute;
