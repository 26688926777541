import React from "react";

import BadgeIcon from "@mui/icons-material/Badge";
import PhoneIcon from "@mui/icons-material/LocalPhone";
import { Box, Card, Divider, ListItem, styled } from "@mui/material";
import { useMemo } from "react";

const StyledListItem = styled(ListItem)`
  align-items: flex-start;
  color: ${({ theme }) => theme.palette.gray_5};
  font-size: 0.8rem;
  gap: 0.5rem;
  padding-block: 1rem;
  ul {
    .data {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.gray_9};
      width: 100%;
    }
  }
`;

const DeliManInfo = ({ data }) => {
  let user = useMemo(() => JSON.parse(localStorage.getItem("user_info")), []);
  return (
    <Card sx={{ paddingTop: "1rem", marginBottom: "1rem" }}>
      <h4 style={{ marginLeft: "1rem" }}>Delivery Man Information</h4>
      <Divider sx={{ marginInline: "1rem", marginTop: "0.75rem" }} />
      <Box display="flex" flexDirection="column" gap="-1rem">
        <StyledListItem>
          <BadgeIcon sx={{ fontSize: "1.25rem" }} />
          <ul>
            <li>Delivery Man Name</li>
            <li className="data">{data?.name ? data?.name : user?.name}</li>
          </ul>
        </StyledListItem>
        <StyledListItem sx={{ marginTop: "-1rem" }}>
          <PhoneIcon sx={{ fontSize: "1.25rem" }} />
          <ul>
            <li>Phone Number</li>
            <li className="data">{data?.phone ? data?.phone : user?.phone}</li>
          </ul>
        </StyledListItem>
      </Box>
    </Card>
  );
};

export default DeliManInfo;
