import React, { useEffect } from "react";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  styled,
  Toolbar,
} from "@mui/material";
import ArrowIcon from "@mui/icons-material/KeyboardBackspace";
import OrderCard from "../orders/OrderCard";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { requestData } from "../../lib/rest_api_helper";
import FallbackLoading from "../FallbackLoading";
import { EmptyMessage } from "../../styled/GlobalStyled";
import { ErrorText } from "../../pages/OrderList";

const StyledBar = styled(AppBar)`
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  position: fixed;
  top: 0;
  left: 0;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  input {
    width: 100%;
    height: 36px;
    background-color: ${({ theme }) => theme.palette.gray_2};
    border: none;
    border-radius: 6px;
    padding-left: 1rem;
    font-size: 1rem;
  }
`;

const StyledOrderList = styled(Box)`
  width: 100%;
  height: calc(100vh - 1rem);
  max-width: 760px !important;
  margin-inline: auto;
  padding-top: 4rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  p {
    margin-top: 1rem;
    text-align: center;
  }
`;

const SearchPage = ({ openDrawer, toggleDrawer }) => {
  const [searchValue, setSearchValue] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const [error, setError] = useState("");

  const { role, userID } = useSelector((state) => state.auth);

  const { data, isLoading, isError } = useQuery(
    ["orders", debounceValue, userID, role],
    () => getOrders(),
    {
      onError: (err) => {
        switch (err) {
          case "Request failed with status code 401":
            setError("Session Expired. Please login again");
            break;
          case "AxiosError: Network Error":
            setError("Network Error. Check your connection");
            break;
        }
      },
    }
  );

  async function getOrders() {
    if (!(role && userID && debounceValue)) return;
    let query =
      "?status=" +
      role +
      "&assign_to=" +
      userID +
      "&order_number=" +
      debounceValue;
    try {
      const { data } = await requestData("orders" + query, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (data.code === 200 && data?.results?.items)
        return data?.results?.items;
      else return [];
    } catch (error) {
      throw new Error(error).message;
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(searchValue);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      ModalProps={{
        keepMounted: true,
      }}
      transitionDuration={500}
    >
      <Box
        sx={{ width: "100vw", paddingInline: "1rem", paddingBlock: "0.5rem" }}
      >
        <StyledBar component="nav">
          <StyledToolbar>
            <IconButton onClick={toggleDrawer} sx={{ marginLeft: "-0.5rem" }}>
              <ArrowIcon sx={{ color: "#555555" }} />
            </IconButton>
            <input
              autoFocus
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </StyledToolbar>
        </StyledBar>
        {isLoading ? (
          <FallbackLoading />
        ) : isError ? (
          <ErrorText>{error}</ErrorText>
        ) : (
          <StyledOrderList display="flex" flexDirection="column" gap="1rem">
            {debounceValue && data?.length === 0 ? (
              <ErrorText className="empty">
                Cannot find any orders with these keywords
              </ErrorText>
            ) : data?.length > 0 ? (
              <>
                {data?.map((item, index) => (
                  <OrderCard data={item} key={index} />
                ))}
              </>
            ) : (
              ""
            )}
          </StyledOrderList>
        )}
      </Box>
    </Drawer>
  );
};

export default SearchPage;
