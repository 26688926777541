import React, { useEffect, useState } from "react";
import { EmptyMessage, Main } from "../styled/GlobalStyled";

import AppBar from "../components/utils/PrimaryAppBar";
import { List } from "@mui/material";

import LogCard from "../components/activity-logs/LogCard";
import { useInfiniteQuery } from "react-query";
import { requestData } from "../lib/rest_api_helper";
import FallbackLoading from "../components/FallbackLoading";
import { ErrorText } from "./OrderList";
import ThreeDotsLoading from "../components/utils/ThreeDotsLoading";

const ActivityLog = () => {
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState("");
  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetchError,
    isSuccess,
  } = useInfiniteQuery(
    "activity_logs",
    ({ pageParam = 1 }) => getActivityLogs(pageParam),
    {
      getNextPageParam: (_, allPages) => {
        const nextPage = allPages.length + 1;
        return allPages.length < totalPages ? nextPage : totalPages;
      },
      onError: (err) => {
        console.log({ err });
        switch (err) {
          case "AxiosError: Request failed with status code 401":
            setError("Session Expired. Please login again");
            break;
          case "AxiosError: Network Error":
            setError("Network Error. Check your connection");
            break;
          default:
            setError("Something Went Wrong");
            break;
        }
      },
    }
  );

  async function getActivityLogs(page) {
    try {
      const { data } = await requestData(
        `op-activities${page ? "?page=" + page : ""}`
      );
      if (page === 1) {
        const pages = Math.ceil(data?.meta?.total / 20);
        setTotalPages(pages);
      }
      if (data.data) return data.data;
      return [];
    } catch (error) {
      throw new Error(error).message;
    }
  }
  useEffect(() => {
    let fetching = false;
    const handleScroll = async (e) => {
      const { scrollHeight, scrollTop, clientHeight } =
        e.target.scrollingElement;
      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.2) {
        fetching = true;
        if (hasNextPage && data.pages.length < totalPages)
          await fetchNextPage();
        fetching = false;
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [fetchNextPage, hasNextPage]);

  return (
    <Main>
      <AppBar text="Activity Logs" />

      {isLoading ? (
        <FallbackLoading />
      ) : isError ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <>
          {isSuccess && data?.pages?.length > 0 ? (
            <List sx={{ paddingTop: "1rem", position: "unset" }}>
              {data?.pages?.map((page, index) => (
                <div key={index}>
                  {page?.length > 0 ? (
                    page?.map((log) => <LogCard key={log?.id} data={log} />)
                  ) : (
                    <EmptyMessage className="empty">
                      You don't have any Logs yet
                    </EmptyMessage>
                  )}
                </div>
              ))}
            </List>
          ) : (
            ""
          )}
        </>
      )}
      {(isRefetchError || isFetchingNextPage) && data?.pages?.length > 0 && (
        <ThreeDotsLoading error={isRefetchError} />
      )}
    </Main>
  );
};

export default ActivityLog;
