import React, { useEffect, useRef } from "react";
import { Button, ButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";

import ListAltIcon from "@mui/icons-material/ListAlt";
import HistoryIcon from "@mui/icons-material/History";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { motion, useAnimation } from "framer-motion";

import NotiSound from "../../assets/notification.mp3";

const StyledFilled = styled(Button)`
  width: 100%;
  border-radius: 100vw;
  color: white;
  font-size: 0.8rem;
  text-transform: capitalize;
  max-width: 24rem;
  height: 2.8rem;
  font-family: "Poppins", sans-serif;
  margin-inline: auto;
  white-space: nowrap;
  :disabled {
    background-color: ${({ theme }) => theme.palette.gray_2};
    color: ${({ theme }) => theme.palette.gray_5};
  }
`;

export function FilledButton({ text, func, disabled }) {
  return (
    <StyledFilled
      disabled={disabled}
      onClick={func}
      variant="contained"
      disableElevation
      name="Apply"
    >
      {text}
    </StyledFilled>
  );
}

const StyledOutlined = styled(Button)`
  width: 100%;
  height: 2.8rem;
  border-radius: 100vw;
  font-size: 0.8rem;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  max-width: 24rem;
  border-width: 2px;
`;

export function OutlinedButton({ text, func }) {
  return (
    <StyledOutlined
      name="Fail"
      onClick={func}
      variant="outlined"
      disableElevation
    >
      {text}
    </StyledOutlined>
  );
}

const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: fixed;
  left: 0;
  bottom: 0;
  border: none;
`;

const StyledButton = styled("button")`
  position: relative;
  height: 100%;
  font-size: 12px;
  color: ${(props) =>
    props.active
      ? props.theme.palette.primary.main
      : props.theme.palette.gray_5};
  border: none;
  background-color: transparent;
  padding-block: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-direction: column;
  background-color: white;
  border-top: solid 2px
    ${(props) =>
      props.active
        ? props.theme.palette.primary.main
        : props.theme.palette.gray_0};
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    border-top: solid 2px ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledBadge = styled(motion.div)`
  position: absolute;
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 1rem;
  width: 1rem;
  border-radius: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -1rem;
  top: -0.25rem;
  font-size: 10px;
`;

//Order List, Activity Logs, Notifications, Account

export function FooterButtons() {
  const { count } = useSelector((state) => state.notifications);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const animation = useAnimation();

  const NotiRef = useRef();

  useEffect(() => {
    if (count > 0) {
      animation.start({
        scale: 1,
        transition: {
          type: "spring",
          duration: 0.3,
          stiffness: 500,
        },
      });
      // NotiRef.current.play();
    }
  }, [count]);
  // pathname === "/notifications" ||
  if (
    pathname === "/orders" ||
    pathname === "/activity-logs" ||
    pathname === "/account"
  ) {
    return (
      <StyledButtonGroup variant="text">
        <StyledButton
          name="Order List"
          active={pathname === "/orders"}
          onClick={() => navigate("/orders")}
        >
          <ListAltIcon />
          Order List
        </StyledButton>
        <StyledButton
          name="Activity Log"
          active={pathname === "/activity-logs"}
          onClick={() => navigate("/activity-logs")}
        >
          <HistoryIcon />
          Activity Log
        </StyledButton>
        <StyledButton
          name="Account"
          active={pathname === "/account"}
          onClick={() => navigate("/account")}
        >
          <PersonIcon sx={{ transform: "scale(1.25)" }} />
          Account
        </StyledButton>
      </StyledButtonGroup>
    );
  } else return "";
}

// <StyledButton
//   name="Notifications"
//   active={pathname === "/notifications"}
//   onClick={() => navigate("/notifications")}
// >
//   <div style={{ position: "relative" }}>
//     <NotificationsIcon />
//     <audio
//       src={NotiSound}
//       ref={NotiRef}
//       style={{ position: "absolute", opacity: "0" }}
//     />
//     {count > 0 && (
//       <StyledBadge
//         key={count}
//         initial={{
//           scale: "1.25",
//         }}
//         animate={animation}
//       >
//         {count > 9 ? "9+" : count}
//       </StyledBadge>
//     )}
//   </div>
//   Notification
// </StyledButton>
