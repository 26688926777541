import axios from "axios";

const URL = "https://api.olivego.com/admin/";

export const requestData = axios.create({
  baseURL: URL,
  headers: {
    "Content-type": "application/json",
  },
});

requestData.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token"); // Replace with your actual bearer token
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
