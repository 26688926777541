import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Card } from "@mui/material";
import { Typography, styled, Stack } from "@mui/material";
import { FilledButton, OutlinedButton } from "./utils/Buttons.js";
import { useNavigate } from "react-router";
import { QueryCache } from "react-query";

const StyledDialog = styled(Dialog)`
  padding: 1rem;
`;

const StyledCard = styled(Card)`
  width: 100%;
  padding: 1.5rem 2rem;
`;

const TypoBold = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.gray_9};
  text-align: center;
`;
const TypoP = styled(Typography)`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.gray_4};
  text-align: center;
  max-width: 30ch;
  margin-bottom: 1rem;
  margin-inline: auto;
`;

export default function LogoutDialog({ openModal, handleClose }) {
  const navigate = useNavigate();
  const queryCaches = new QueryCache({
    onError: (err) => console.log(err),
  });
  const logout = () => {
    localStorage.removeItem("remember");
    localStorage.removeItem("access_token");
    queryCaches.clear();
    navigate("/login");
  };
  return (
    <>
      <StyledDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
        open={openModal}
      >
        <StyledCard>
          <TypoBold gutterBottom>Are you sure to logout?</TypoBold>
          <TypoP>We can’t notify you of anything in the process</TypoP>
          <Stack direction="row" spacing="1.5rem">
            <OutlinedButton text="cancel" func={handleClose} />
            <FilledButton text="logout" func={logout} />
          </Stack>
        </StyledCard>
      </StyledDialog>
    </>
  );
}
