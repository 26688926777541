import { styled } from "@mui/material";

export const Main = styled("main")`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-bottom: 7rem;
  padding-inline: 1rem;
  background-color: ${({ theme }) => theme.palette.primary};
  position: relative;
  figure {
    width: 100%;
    max-width: 230px;
    margin-bottom: 1rem;
    position: absolute;
    top: 9rem;
    img {
      object-fit: contain;
      width: 100%;
    }
  }
  form {
    position: absolute;
    top: 14rem;
    width: 100%;
    max-width: 22rem;
    min-height: 15rem;
    padding-inline: 1rem;
    padding-block: 2rem;
    background-color: white;
    border-radius: 5px;
    box-shadow: ${({ theme }) => theme.shadows[1]};
    div {
      margin-bottom: 2rem;
    }
    .checkbox {
      margin-bottom: 1.25rem;
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      gap: 0.5rem;
      color: ${({ theme }) => theme.palette.gray_3};
      input {
        transform: scale(1.3);
        margin-left: 0.2rem;
      }
    }
  }
`;

export const ErrorText = styled("p")`
  position: absolute;
  color: ${({ theme }) => theme.palette.red_1};
  font-size: 0.7rem;
  top: 105%;
  right: 0;
  width: 100%;
  text-align: ${({ align }) => (align ? align : "left")};
`;
