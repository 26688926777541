import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { FooterButtons } from "./components/utils/Buttons";

import FallbackLoading from "./components/FallbackLoading";
import ProtectedRoute from "./components/ProtectedRoute";

import OrdersListPage from "./pages/OrderList";
import OrderDetailsPage from "./pages/OrderDetails";
import LoginPage from "./pages/Login";
import ActivityLogsPage from "./pages/ActivityLog";
import ActivityLogSummaryPage from "./pages/ActivityLogSummary";
import AccountPage from "./pages/Account";
import NotificationsPage from "./pages/Notifications";
import ContactListPage from "./pages/ContactList";
import SuccessPage from "./pages/Success";
import WriteNotesPage from "./pages/WriteNotes";

const RouterComponent = () => {
  return (
    <Router>
      <Suspense fallback={<FallbackLoading />}>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Navigate to={"/orders"} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <OrdersListPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:id"
            element={
              <ProtectedRoute>
                <OrderDetailsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/success"
            element={
              <ProtectedRoute>
                <SuccessPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/activity-logs"
            element={
              <ProtectedRoute>
                <ActivityLogsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/activity-logs/:id"
            element={
              <ProtectedRoute>
                <ActivityLogSummaryPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <AccountPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <NotificationsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contact-list"
            element={
              <ProtectedRoute>
                <ContactListPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/write-notes"
            element={
              <ProtectedRoute>
                <WriteNotesPage />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </Suspense>
      <FooterButtons />
    </Router>
  );
};

export default RouterComponent;
