import { styled } from "@mui/material";
import React from "react";

const Container = styled("div")`
  width: 100%;
  padding-top: 1rem;
  h5 {
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.gray_9};
  }
  .product {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: white;
    box-shadow: ${({ theme }) => theme.shadows[1]};
    border-radius: 0.25rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
  }
  figure {
    margin-inline: 0.5rem;
    height: 60px;
    width: 60px;
    img {
      width: 60px;
      border-radius: 4px;
      aspect-ratio: 1 / 1;
    }
  }
`;

export default function CouponServices({ services = [] }) {
  return (
    <Container>
      <h5>Services</h5>
      {services?.map((service, ind) => (
        <div key={ind} className="product">
          <figure>
            <img
              src={service?.service?.media?.thumbnail}
              alt={service.service.name}
            />
          </figure>
          <h6 className="name">{service.service.name}</h6>
        </div>
      ))}
    </Container>
  );
}
