import { createSlice } from "@reduxjs/toolkit";

export const roles = {
  13: "picker",
  14: "qc",
  15: "packer",
  16: "delivery",
  17: "delivering",
};

const initialState = {
  role: null,
  authorized: true,
  userID: null,
  name: "",
  email: "",
  phone: "",
  permissions: [],
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    Login: (state, action) => {
      if (roles[action.payload.role]) {
        state.role = roles[action.payload.role];
        state.authorized = true;
        state.userID = action.payload.user_id;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.permissions = action.payload.permissions;
      }
    },
    IsAuthed: (state, action) => {
      state.authorized = action.payload.authed;
    },
  },
});

// Action creators are generated for each case reducer function
export const { Login, IsAuthed } = AuthSlice.actions;

export default AuthSlice.reducer;
