import { Box, Button, ListItem, styled } from "@mui/material";

export const StyledBox = styled(Box)`
  width: 100%;
  padding: 1rem 1rem 0rem 1rem;
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  border-radius: 6px;
  .mt {
    margin-top: 0.5rem;
  }
  .total {
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    h6,
    p {
      font-size: 0.8rem;
    }
  }
  .total:first-child {
    margin-top: 0.3rem;
  }
`;

export const StyledListItem = styled(ListItem)`
  padding: 0;
  padding-block: 1rem;
  height: 6rem;
  border-block: solid 1px ${({ theme }) => theme.palette.gray_2};
  width: 100%;
  figure {
    width: 60px;
    aspect-ratio: 1 / 1;
    margin-right: 1rem;
    img {
      border-radius: 4px;
      width: 60px;
      aspect-ratio: 1 / 1;
    }
  }
`;

export const ItemText = styled("div")`
  width: 100%;
  .name {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.gray_9};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-name {
      width: 10rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .price {
      font-weight: 600;
    }
  }
  .qty {
    font-weight: 600;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.gray_6};
  }
  .scratch {
    text-decoration: line-through;
    margin-right: 0.5rem;
    text-decoration-color: red;
    text-decoration-thickness: 2px;
  }
  .variations {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    justify-content: flex-start;
    margin-top: 3px;
    .variation {
      font-size: 0.6rem;
      padding-inline: 0.2rem;
      padding-block: 0.1rem;
      white-space: nowrap;
    }
    .color {
      width: 1.4rem;
      height: 1rem;
    }
  }
`;

export const ProductsContainer = styled(Box)`
  width: 100%;
  max-height: ${({ expand }) => (expand === "true" ? "100%" : "18rem")};
  overflow: hidden;
`;

export const SeeMore = styled(Button)`
  font-size: 0.8rem;
  width: 100%;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  padding-block: 0.75rem;
`;
