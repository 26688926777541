import React, { useEffect, useState } from "react";
import { EmptyMessage, Main } from "../styled/GlobalStyled";
import AppBar from "../components/utils/PrimaryAppBar";
import { Box, styled } from "@mui/material";
import NotificationCard from "../components/notifications/NotiCard";
import { useInfiniteQuery } from "react-query";
import { requestData } from "../lib/rest_api_helper";
import FallbackLoading from "../components/FallbackLoading";
import { ErrorText } from "./OrderList";
import ThreeDotsLoading from "../components/utils/ThreeDotsLoading";
import { useDispatch, useSelector } from "react-redux";
import { ResetNotiCount } from "../lib/slices/NotificationsSlice";

const StyledOrderList = styled(Box)`
  margin-top: 1.25rem;
  width: 100%;
`;

const Notifications = () => {
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState("");

  const { role } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetchError,
    isSuccess,
    refetch,
  } = useInfiniteQuery(
    ["notifications", role],
    ({ pageParam = 1 }) => getNotifications(pageParam),
    {
      getNextPageParam: (_, allPages) => {
        const nextPage = allPages.length + 1;
        return allPages.length < totalPages ? nextPage : totalPages;
      },
      onError: (err) => {
        switch (err) {
          case "AxiosError: Request failed with status code 401":
            setError("Session Expired. Please login again");
            break;
          case "AxiosError: Network Error":
            setError("Network Error. Check your connection");
            break;
          default:
            setError("Something Went Wrong");
            break;
        }
      },
      refetchInterval: 180000,
    }
  );

  async function getNotifications(page) {
    if (!role) return;
    try {
      const { data } = await requestData(
        `op-notifications${page ? "?page=" + page : ""}`
      );
      if (page === 1) {
        const pages = Math.ceil(data?.meta?.total / 20);
        setTotalPages(pages);
      }
      if (data.data) return data.data;
      else throw Error(data).message;
    } catch (error) {
      throw new Error(error).message;
    }
  }

  useEffect(() => {
    let fetching = false;
    const handleScroll = async (e) => {
      const { scrollHeight, scrollTop, clientHeight } =
        e.target.scrollingElement;
      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.2) {
        fetching = true;
        if (hasNextPage && data.pages.length < totalPages)
          await fetchNextPage();
        fetching = false;
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    dispatch(ResetNotiCount());
  }, []);

  return (
    <Main>
      <AppBar text="Notification" />
      {isLoading ? (
        <FallbackLoading />
      ) : isError ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <>
          {isSuccess && data?.pages?.length > 0 ? (
            <StyledOrderList display="grid" gridTemplateColumns="1fr">
              {data?.pages?.map((page, ind) => (
                <div key={ind}>
                  {page?.length > 0 ? (
                    page?.map((data, index) => (
                      <NotificationCard
                        refetch={refetch}
                        data={data}
                        key={index}
                      />
                    ))
                  ) : (
                    <EmptyMessage className="empty">
                      You don't have any Notifications yet
                    </EmptyMessage>
                  )}
                </div>
              ))}
            </StyledOrderList>
          ) : (
            ""
          )}
        </>
      )}
      {(isRefetchError || isFetchingNextPage) && data?.pages?.length > 0 && (
        <ThreeDotsLoading error={isRefetchError} />
      )}
    </Main>
  );
};

export default Notifications;
