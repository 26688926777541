import React from "react";
import { useSelector } from "react-redux";

import { Divider } from "@mui/material";

import DoneProductList from "../components/orders/DoneProductList";
import CustomerInfo from "../components/utils/CustomerInfo";
import OrderDetailsBox from "../components/orders/OrderDetailsBox";
import DeliManInfo from "../components/utils/DeliManInfo";

import BackToLogsAppBar from "../components/utils/AppBarWithBackBtn";

import { Notes } from "../pages/Success";
import { useMemo } from "react";
import { useLocation } from "react-router";
import CouponProducts from "../components/orders/CouponProducts";
import CouponServices from "../components/orders/CouponServices";

const status = {
  picker: "qc",
  qc: "packing",
  packer: "delivery",
  delivery: "delivering",
  delivering: "delivered",
};

const Success = () => {
  const { state: order } = useLocation();
  let detail = { ...order };
  if (order?.diff_area_status === 1) {
    detail.customer = {
      address: `${order?.shipping_address}, ${order?.shipping_township?.name}`,
      email: order?.shipping_email,
      name: order?.shipping_name,
      phone: order?.shipping_phone,
    };
  } else {
    detail.customer = {
      address: `${order?.billing_address}, ${order?.billing_township?.name}`,
      email: order?.billing_email,
      name: order?.billing_name,
      phone: order?.billing_phone,
    };
  }
  Object.keys(detail).forEach((key) => {
    if (key.startsWith("shipping") || key.startsWith("billing")) {
      delete detail[key];
    }
  });
  const {
    auth: { role },
  } = useSelector((state) => state);

  const note = useMemo(() => {
    const result = detail?.assigns?.find(
      (tracking) => tracking.status === status[role]
    );

    return result?.note;
  }, []);

  return (
    <main
      style={{
        padding: "5rem 1rem 2rem 1rem",
      }}
    >
      <BackToLogsAppBar />
      {["picker", "qc", "packer"].includes(role) && (
        <CustomerInfo date={detail?.created_at} customer={detail?.customer} />
      )}
      {role === "picker" && <DoneProductList done={true} data={detail?.info} />}
      {role === "qc" && (
        <DoneProductList
          done={true}
          text="Checked Quality of"
          data={detail?.info}
        />
      )}
      {!["picker", "qc", "packer"].includes(role) && (
        <CustomerInfo date={detail?.created_at} customer={detail?.customer} />
      )}
      {(role === "delivery" || role === "delivering") && (
        <>
          <DeliManInfo
            data={{
              name: detail?.assigns[4]?.assign_to?.name,
              phone: detail?.assigns[4]?.assign_to?.phone,
            }}
          />
          <OrderDetailsBox data={detail} />
        </>
      )}
      {detail?.coupon_promo?.products?.length > 0 && (
        <CouponProducts products={detail?.coupon_promo?.products} />
      )}
      {detail?.coupon_promo?.services?.length > 0 && (
        <CouponServices services={detail?.coupon_promo?.services} />
      )}
      {role === "packer" && (
        <DoneProductList done={true} text="Packed" data={detail?.info} />
      )}
      <Notes>
        <h4>Additional Notes</h4>
        <p>{note}</p>
        {(role === "delivery" || role === "delivering") && <Divider />}
      </Notes>
    </main>
  );
};

export default Success;
