import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  detail: {},
};

export const ActivityLogSlice = createSlice({
  name: "activity_log",
  initialState,
  reducers: {
    SetLogDetail: (state, action) => {
      state.detail = action.payload.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { SetLogDetail } = ActivityLogSlice.actions;

export default ActivityLogSlice.reducer;
