import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { requestData } from "./lib/rest_api_helper";
import { IsAuthed, Login } from "./lib/slices/AuthSlice";
import { SetNotiCount } from "./lib/slices/NotificationsSlice";
import RouterComponent from "./Router";

const theme = createTheme({
  palette: {
    primary: {
      main: "#75b206",
    },
    secondary: { main: "#b3c535" },
    red_1: "#EA5A00",
    gray_0: "#00000029",
    gray_1: "#fafafa",
    gray_2: "#eeeeee",
    gray_3: "#888888",
    gray_4: "#707070",
    gray_5: "#a7a7a7",
    gray_6: "#555555",
    gray_9: "#222222",
  },
  shadows: [
    "none",
    "0px 0px 4px #00000029",
    "0px 0px 6px #00000029",
    ...Array(22).fill("none"),
  ],
});

function App() {
  const [notifications, setNotifications] = useState(10000);

  const { role } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    if (window) {
      const token = localStorage.getItem("access_token");
      const remember = localStorage.getItem("remember");
      if (token && remember === "true") {
        requestData.get("me").then(({ data: res }) => {
          const {
            user: {
              role_id,
              email,
              name,
              id,
              phone,
              role: { permissions },
            },
          } = res.result;
          dispatch(
            Login({
              role: role_id,
              user_id: id,
              name,
              email,
              permissions,
              phone,
            })
          );
        });
      } else dispatch(IsAuthed({ authed: false }));
    }
  }, []);

  const { data } = useQuery("check_notifications", checkNewNoti, {
    refetchInterval: 60000,
  });

  async function checkNewNoti() {
    try {
      if (!role) return;
      try {
        const { data } = await requestData(
          "back/operation/notifications?role=" + role,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        if (data.results.total) {
          if (+data.results.total > notifications) {
            dispatch(
              SetNotiCount({ count: +data.results.total - notifications })
            );
          }
          setNotifications(+data.results.total);
        } else {
          setNotifications(0);
        }
      } catch (error) {
        throw new Error(error).message;
      }
    } catch (error) {}
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <RouterComponent />
      </div>
    </ThemeProvider>
  );
}

export default App;
