import { configureStore } from "@reduxjs/toolkit";
import ActivityLogReducer from "./slices/ActivityLogSlice";
import AuthReducer from "./slices/AuthSlice";
import NotificationsReducer from "./slices/NotificationsSlice";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    activity_log: ActivityLogReducer,
    notifications: NotificationsReducer,
  },
});
