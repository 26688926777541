import { styled } from "@mui/material";
import React from "react";
import { ThreeDots } from "react-loader-spinner";

const Div = styled("div")`
  height: 4rem;
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: -2rem;
  .error {
    text-align: center;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.gray_5};
  }
`;

const ThreeDotsLoading = ({ error = false }) => {
  return (
    <Div>
      {error !== true ? (
        <ThreeDots height="30" width="30" color="#75b206" />
      ) : (
        <p className="error">Error, Try Later</p>
      )}
    </Div>
  );
};

export default ThreeDotsLoading;
