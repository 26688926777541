import React, { useState } from "react";
import { useSelector } from "react-redux";

import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  ControlBtn,
  ControlQty,
  StyledListItem,
  StyledCard,
  ItemText,
  StyledParentProduct,
  FreeProducts,
} from "../../styled/ProductQtyControlCard";

const ProductControlCard = ({ item, controlQty, index, changedQty }) => {
  const [newQty, setNewQty] = useState(
    +item?.available_qty ? item?.available_qty : item?.quantity
  );
  const { role } = useSelector((state) => state.auth);
  const handleQuantity = (action) => {
    if (action === "add") {
      setNewQty((prev) => {
        if (prev === item.quantity) {
          controlQty(index, {
            id: item?.sku?.product?.id,
            sku: item?.sku?.id,
            available_qty: prev,
          });
          return prev;
        }
        controlQty(index, {
          id: item?.sku?.product?.id,
          sku: item?.sku?.id,
          available_qty: prev + 1,
        });
        return prev + 1;
      });
    }
    if (action === "remove")
      setNewQty((prev) => {
        if (prev === 0) {
          controlQty(index, {
            id: item?.sku?.product?.id,
            sku: item?.sku?.id,
            available_qty: 0,
          });
          return prev;
        }
        controlQty(index, {
          id: item?.sku?.product?.id,
          sku: item?.sku?.id,
          available_qty: prev - 1,
        });
        return prev - 1;
      });
  };

  const showControl = () => {
    if (changedQty.clickedProduct !== index) {
      controlQty(index);
    }
    if (changedQty.clickedProduct === index) {
      controlQty(null);
    }
  };
  return (
    <StyledCard match={`${newQty === item.quantity}`}>
      <StyledParentProduct>
        <ControlQty
          disabled={true}
          // onClick={() => {
          //   if (role !== "picker") return;
          //   showControl();
          // }}
        >
          <p>{newQty}</p>
          {/* {role === "picker" && (
          <ArrowDownIcon sx={{ marginRight: "-0.5rem", color: "#d5d5d5" }} />
        )} */}
          {changedQty.clickedProduct === index && role === "picker" && (
            <ControlBtn
              initial={{ x: "-1rem" }}
              animate={{
                x: 0,
                transition: {
                  type: "spring",
                  duration: 0.3,
                  stiffness: 100,
                },
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <button onClick={() => handleQuantity("remove")}>
                <RemoveIcon sx={{ fontSize: "1.25rem" }} />
              </button>
              <button onClick={() => handleQuantity("add")}>
                <AddIcon sx={{ fontSize: "1.25rem" }} />
              </button>
            </ControlBtn>
          )}
        </ControlQty>
        <StyledListItem
          clicked={`${
            changedQty.clickedProduct === index && role === "picker"
          }`}
        >
          <figure>
            <img
              src={item?.sku?.product?.media[0]?.thumbnail}
              alt={item?.sku?.product?.name}
            />
          </figure>
          <ItemText>
            <h6 className="name">{item.sku?.product?.name}</h6>
            <p className="qty">Requested Quantity : {item?.quantity}</p>
            <div className="variations">
              {item?.sku?.variations?.map((vari) => {
                if (vari?.variation?.type_value === "default") return "";
                const color = vari?.variation?.type_value?.startsWith("#")
                  ? vari?.variation?.type_value
                  : null;
                if (color)
                  return (
                    <p
                      key={vari.id}
                      className="color"
                      style={{ backgroundColor: color }}
                    ></p>
                  );
                else
                  return (
                    <p
                      key={vari.id}
                      className="variation"
                      style={{ backgroundColor: "#eee" }}
                    >
                      {vari?.variation.name}
                    </p>
                  );
              })}
            </div>
          </ItemText>
        </StyledListItem>
      </StyledParentProduct>
      {item?.get_y_products && item?.get_y_products?.length > 0 && (
        <FreeProducts>
          <h4 style={{ paddingLeft: "1rem" }}>Gifts</h4>
          <div style={{ paddingInline: "1rem" }}>
            {item?.get_y_products?.map((prod) => (
              <div key={prod.id} className="item">
                <figure>
                  <img src={prod?.image} alt={prod?.name} />
                </figure>
                <ItemText>
                  <h6 className="name">{prod?.name}</h6>
                  <div className="variations">
                    {prod?.variations?.map((vari) => {
                      if (vari?.type_value === "default") return "";
                      const color = vari?.type_value?.startsWith("#")
                        ? vari?.type_value
                        : null;
                      if (color)
                        return (
                          <p
                            key={vari.id}
                            className="color"
                            style={{ backgroundColor: color }}
                          ></p>
                        );
                      else
                        return (
                          <p
                            key={vari.id}
                            className="variation"
                            style={{ backgroundColor: "#eee" }}
                          >
                            {vari?.name}
                          </p>
                        );
                    })}
                  </div>
                </ItemText>
              </div>
            ))}
          </div>
        </FreeProducts>
      )}
    </StyledCard>
  );
};

export default ProductControlCard;
