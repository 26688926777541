import { Card, Dialog, styled } from "@mui/material";

export const StyledDialog = styled(Dialog)`
  /* padding: 1rem; */
`;

export const StyledCard = styled(Card)`
  padding: 1.5rem 1rem;
  h5 {
    font-size: 1rem;
  }
`;

export const DateButtons = styled("div")`
  margin-top: 1.75rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  @media screen and (min-width: 480px) {
    justify-content: center;
  }
`;

export const ApplyButtons = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding-inline: 1rem;
  gap: 2rem;
`;

export const Button = styled("button")`
  margin-top: 0.25rem;
  height: 2rem;
  border-radius: 100vw;
  padding-inline: 0.75rem;
  background-color: ${({ active, theme }) =>
    active === "true" ? theme.palette.gray_6 : "white"};
  color: ${({ active, theme }) =>
    active === "true" ? "white" : theme.palette.gray_5};
  border: solid 1px
    ${({ active, theme }) =>
      active === "true" ? theme.palette.gray_6 : "#d5d5d5"};
`;
